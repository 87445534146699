<template>
  <el-collapse v-model="activeName" accordion>
    <el-collapse-item title="样式" name="1">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">图标</span></el-col>
        <el-col :span="19">
          <div class="flex-def flex-zBetween flex-cCenter">
            <div class="flex-def flex-cCenter" style="user-select:none;">
              <y_upload_img_box style="margin-right: .5rem" @change="onChange" v-model="coms_con.data.src"></y_upload_img_box>
              <div>
                <y_choose_link @change="onChange" v-model="coms_con.data.link"></y_choose_link>
                <span class="y-desc">图片宽自定义高等比例缩放</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">图标宽</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :min="50" :max="160" input-size="mini" v-model="coms_con.data.img_width" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">左右定位</span></el-col>
        <el-col :span="19">
          <el-radio-group @change="onChange" size="small" v-model="coms_con.data.pos_lr">
            <el-radio-button label="right">右</el-radio-button>
            <el-radio-button label="left">左</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">上下定位</span></el-col>
        <el-col :span="19">
          <el-radio-group @change="onChange" size="small" v-model="coms_con.data.pos_tb">
            <el-radio-button label="top">上</el-radio-button>
            <el-radio-button label="bottom">下</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">上间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_top" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">下间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_bottom" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">右间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_right" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">左间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_left" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">上边角</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="160" input-size="mini" v-model="coms_con.data.border_r_top" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">下边角</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="160" input-size="mini" v-model="coms_con.data.border_r_bottom" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item title="阴影效果" name="3">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">阴影色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" :alpha="true" v-model="coms_con.data.shadow_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">水平</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="25" :min="-25" input-size="mini" v-model="coms_con.data.shadow_h" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">垂直</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="25" :min="-25" input-size="mini" v-model="coms_con.data.shadow_v" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">模糊</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.shadow_blur" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">尺寸</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.shadow_spread" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import y_color_choose from "@/components/y_color_choose";
import y_upload_img_box from "@/components/y_upload_img_box";
import y_choose_link from "@/components/y_choose_link";
export default {
  name: "y-float-btn",
  components:{
    y_color_choose,
    y_upload_img_box,
    y_choose_link,
  },
  data(){
    return{
      coms_con:this.coms,
      activeName:"1",
    }
  },
  props:{
    coms:{
      type:Object,
      default(){
        return{
          title:"浮动按钮",
          name:"y-float-btn",
          data:{
            src:"",
            link:"",
            img_width:80,
            pos_tb:"bottom",
            pos_lr:"right",
            margin_top: 0,
            margin_bottom: 0,
            margin_left: 0,
            margin_right: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
          }
        }
      }
    }
  },
  watch:{
    coms(){
      this.coms_con = this.coms;
    }
  },
  methods:{
    onChange(){
      this.$emit("change",this.coms_con);
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
.el-row{
  margin-bottom: 1rem;
}
</style>
import common from "@/view/active/edit/design/coms/business/common";

export default {
    title: "业务组件",
    name: "business",
    list: [
        ...common,
        {
            title: "底部按钮",
            name: "y-group-buy-bottom-btn",
            data: {
                buy_btn_font_color: "#ffffff",
                buy_btn_bg_color: "#f82589",
                buy_border_r: 20,
                contet_padding_tb: 15,//0 15
                bg_color: "#ffffff",
                mine_btn_font_color: "#ffffff",
                mine_btn_bg_color: "#fd9320",
                mine_border_r: 20,
                waiter_qrcode: "",
                waiter_phone: "",
            }
        },
        {
            title: "可参团列表",
            name: "y-group-buy-can-apply-order",
            data: {
                margin_top:0,
                margin_bottom:0,
                margin_lr:0,
                border_r_top:0,
                border_r_bottom:0,
                shadow_color:"",
                shadow_h:0,
                shadow_v:0,
                shadow_blur:0,
                shadow_spread:0,
            }
        },
    ]
}
<template>
  <el-collapse v-model="activeName" accordion>
    <el-collapse-item title="样式" name="1">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">元素颜色</span></el-col>
        <el-col :span="19">
          <yColorChoose @change="onChange" v-model="coms_con.data.bg_color"></yColorChoose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">元素高度</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="200" input-size="mini" v-model="coms_con.data.height" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import yColorChoose from "@/components/y_color_choose";
export default {
  name: "y-gap",
  components:{yColorChoose},
  data(){
    return{
      coms_con:this.coms,
      activeName:"1",
    }
  },
  props:{
    coms:{
      type:Object,
      default(){
        return{
          title:"占位间隔",
          name:"y-gap",
          data:{
            height:100,
            bg_color:"red",
          }
        }
      }
    }
  },
  watch:{
    coms(){
      this.coms_con = this.coms;
    }
  },
  methods:{
    onChange(){
      this.$emit("change",this.coms_con);
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
.el-row{
  margin-bottom: 1rem;
}
</style>
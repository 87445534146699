import common from "@/view/active/edit/design/coms/business/common";
export default {
    title: "业务组件",
    name: "business",
    list: [
        ...common,
        {
            title: "底部按钮",
            name: "y-bottom-btn",
            data: {
                buy_btn_text: "立即抢购",
                buy_btn_font_size: 20,//12 30
                buy_btn_font_color: "#ffffff",
                buy_btn_bg_color: "#f82589",
                buy_border_r: 20,
                contet_padding_tb: 15,//0 15
                bg_color: "#ffffff",
                mine_btn_font_size: 20,//12 30
                mine_btn_font_color: "#ffffff",
                mine_btn_bg_color: "#fd9320",
                mine_border_r: 20,
                waiter_qrcode: "",
                waiter_phone: "",
            }
        },

    ]
}
<template>
  <el-collapse v-model="activeName" accordion>
    <el-collapse-item title="内容" name="0">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="6"><span class="y-desc">客服二维码</span></el-col>
        <el-col :span="18">
          <y_upload_img_box @change="onChange" v-model="coms_con.data.waiter_qrcode"></y_upload_img_box>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="6"><span class="y-desc">客服电话</span></el-col>
        <el-col :span="18">
          <el-input @change="onChange" v-model="coms_con.data.waiter_phone" size="mini"></el-input>
        </el-col>
      </el-row>
    </el-collapse-item>

    <el-collapse-item title="基础样式" name="1">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">上下边距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="15" input-size="mini" v-model="coms_con.data.contet_padding_tb" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">背景色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" :alpha="true" v-model="coms_con.data.bg_color"></y_color_choose>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item title="购买按钮" name="2">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">文本</span></el-col>
        <el-col :span="19">
          <el-input @change="onChange" v-model="coms_con.data.buy_btn_text" size="mini"></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">字号</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="30" :min="12" input-size="mini" v-model="coms_con.data.buy_btn_font_size" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">字体色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" v-model="coms_con.data.buy_btn_font_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">背景色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" :alpha="true" v-model="coms_con.data.buy_btn_bg_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">圆角</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.buy_border_r" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item title="我的按钮" name="3">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">字号</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="25" :min="12" input-size="mini" v-model="coms_con.data.mine_btn_font_size" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">字体色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" v-model="coms_con.data.mine_btn_font_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">背景色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" :alpha="true" v-model="coms_con.data.mine_btn_bg_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">圆角</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.mine_border_r" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import y_color_choose from "@/components/y_color_choose";
import y_upload_img_box from "@/components/y_upload_img_box";
export default {
  name: "y-bottom-btn",
  components:{
    y_color_choose,y_upload_img_box
  },
  data(){
    return{
      coms_con:this.coms,
      activeName:"0",
    }
  },
  props:{
    coms:{
      type:Object,
      default(){
        return{
          title:"底部按钮",
          name:"y-bottom-btn",
          data:{
            buy_btn_text:"立即抢购",
            buy_btn_font_size:20,//12 30
            buy_btn_font_color:"#ffffff",
            buy_btn_bg_color:"#f82589",
            buy_border_r:20,
            contet_padding_tb:15,//0 15
            bg_color:"#ffffff",
            mine_btn_font_size:20,//12 30
            mine_btn_font_color:"#ffffff",
            mine_btn_bg_color:"#fd9320",
            mine_border_r:20,
            waiter_qrcode:"",
            waiter_phone:"",
          }
        }
      }
    }
  },
  watch:{
    coms(){
      this.coms_con = this.coms;
    }
  },
  methods:{
    onChange(){
      this.$emit("change",this.coms_con);
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
.el-row{
  margin-bottom: 1rem;
}
</style>
import mode1 from "@/view/active/edit/design/coms/mode1";
import mode4 from "@/view/active/edit/design/coms/mode4";
import mode5 from "@/view/active/edit/design/coms/mode5";
import mode6 from "@/view/active/edit/design/coms/mode6";
export default function registerCom(mode) {
    console.log(mode);
    let out = [];
    switch (parseInt(mode)) {
        case 6:
            out = mode6;
            break;
        case 5:
            out = mode5;
            break;
        case 4:
            out = mode4;
            break;
        case 1:
            out = mode1Real
            break;
        case 2:
        default:
            out = mode1
            break
    }
    return out;
}

import basic from "@/view/active/edit/design/coms/basic/default";
import business from "@/view/active/edit/design/coms/business/voice-red-packet"
export function voiceRegisterCom(){
    return [
        basic,
        business
    ];
}

import shop from "@/view/active/edit/design/coms/business/shop";
import mode1Real from "@/view/active/edit/design/coms/mode1Real";
export function shopRegisterCom(){
    return [
        basic,
        shop
    ]
}
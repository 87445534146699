<template>
  <el-select placeholder="输入门店名称" v-model="value" :remote-method="remoteMethod" :loading="loading" filterable remote>
    <el-option v-for="item in options" :key="item.value" :label="item.title" :value="item.id"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "y_choose_store",
  props:{
    store_id: {
      type:Number,
      default:0
    }
  },
  model:{
    prop:"store_id",
    event:"change"
  },
  watch:{
    value(e){
      this.$emit("change",e)
    },
    store_id(e){
      if(this.store_id > 0){
        this.$api.store.one({id:this.store_id}).then(res=>{
          this.value = e;
          this.options = [{id:e,title:res.title}];
        })
      }else {
        this.value = 0;
        this.options = [{id:0,title:'输入门店名称选择',value:0}];
      }
    }
  },
  data(){
    return{
      value:"",
      options:[],
      loading: false,
    }
  },
  mounted() {
    if(this.store_id > 0) {
      this.$api.store.one({id: this.store_id}).then(res => {
        this.value = this.store_id;
        this.options = [{id: this.store_id, title: res.title}];
      })
    }
  },
  methods:{
    remoteMethod(query) {
      if(query === ''){
        return;
      }
      this.loading = true;
      this.$api.store.search({title:query}).then(res=>{
        this.loading = false;
        this.options = res;
      })
    },
  }
}
</script>

<style scoped>

</style>
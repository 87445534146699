<template>
  <el-collapse v-model="activeName" accordion>
    <el-collapse-item title="样式" name="1">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">弹幕方向</span></el-col>
        <el-col :span="19">
          <el-radio-group @change="onChange" size="small" v-model="coms_con.data.pos_type">
            <el-radio-button label="rightToLeft">右至左</el-radio-button>
            <el-radio-button label="leftToRight">左至右</el-radio-button>
            <el-radio-button label="leftBottom">下至上</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: "y-order-bullet-chat",
  components:{
  },
  data(){
    return{
      coms_con:this.coms,
      activeName:"1",
    }
  },
  props:{
    coms:{
      type:Object,
      default(){
        return{
          title:"订单弹幕",
          name:"y-order-bullet-chat",
          data:{
            pos_type:"rightToLeft",
          }
        }
      }
    }
  },
  watch:{
    coms(){
      this.coms_con = this.coms;
    }
  },
  methods:{
    onChange(){
      this.$emit("change",this.coms_con);
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
.el-row{
  margin-bottom: 1rem;
}
</style>
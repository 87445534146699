export default  {
    title: "聚合选项卡组件",
    name: "variant",
    list: [
        {
            title: "商卡红",
            name: "y-top-tab-mcr",
            data: {
                merchant_list_coms: {
                    col: 2,
                    logo_border_radius: 0,
                    bg_color: "#ffffff",
                    margin_top: 0,
                    margin_bottom: 0,
                    margin_lr: 0,
                    border_r_top: 0,
                    border_r_bottom: 0,
                    shadow_color: "",
                    shadow_h: 0,
                    shadow_v: 0,
                    shadow_blur: 0,
                    shadow_spread: 0,
                },
                red_packet_coms: {
                    bg_color: "#ffffff",
                    margin_top: 0,
                    margin_bottom: 0,
                    margin_lr: 0,
                    border_r_top: 0,
                    border_r_bottom: 0,
                    shadow_color: "",
                    shadow_h: 0,
                    shadow_v: 0,
                    shadow_blur: 0,
                    shadow_spread: 0,
                },
                coupon: {
                    bg_color: "",
                    style: 0,
                    margin_top: 25,
                    margin_bottom: 25,
                    margin_lr: 25,
                    border_r_top: 25,
                    border_r_bottom: 25,
                    shadow_color: "",
                    shadow_h: 0,
                    shadow_v: 0,
                    shadow_blur: 0,
                    shadow_spread: 0,
                },
                tab_default_current: 0,
                tab_inactive_color: "",
                tab_active_color: "",
                bg_color: "#ffffff",
                margin_top: 0,
                margin_bottom: 0,
                margin_lr: 0,
                border_r_top: 0,
                border_r_bottom: 0,
                shadow_color: "",
                shadow_h: 0,
                shadow_v: 0,
                shadow_blur: 0,
                shadow_spread: 0,
            }
        },
    ],
}
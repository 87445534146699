<template>
  <el-collapse v-model="activeName" accordion>
    <el-collapse-item title="样式" name="1">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">背景色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" :alpha="true" v-model="coms_con.data.bg_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">上间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_top" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">下间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_bottom" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import y_color_choose from "@/components/y_color_choose";
export default {
  name: "y-draw-grid",
  components:{
    y_color_choose,
  },
  data(){
    return{
      coms_con:this.coms,
      activeName:"1",
    }
  },
  props:{
    coms:{
      type:Object,
      default(){
        return{
          title:"盲盒",
          name:"y-draw-box",
          data:{
            bg_color:"rgba(255, 255, 255, 0)",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
          }
        }
      }
    }
  },
  watch:{
    coms(){
      this.coms_con = this.coms;
    }
  },
  methods:{
    onChange(){
      this.$emit("change",this.coms_con);
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
.el-row{
  margin-bottom: 1rem;
}
</style>
<template>
  <div style="padding: 2rem">
    <div class="header-title">{{ coms.title }}</div>
    <div style="text-align: right">
      <el-link @click="del" style="font-size: 12px" type="primary" :underline="false">删除此组件</el-link>
    </div>
    <component @change="onChange" :is="coms.name" :coms="coms"></component>
  </div>
</template>

<script>
import yImage from "./design-r-coms-e/y-image";
import yMap from "./design-r-coms-e/y-map";
import yGap from "./design-r-coms-e/y-gap";
import ySwipeImage from "./design-r-coms-e/y-swipe-image";
import yEditor from "./design-r-coms-e/y-editor";
import yVideo from "./design-r-coms-e/y-video";
import yColumn from "./design-r-coms-e/y-column";
import ySpecialBtn from "./design-r-coms-e/y-special-btn";
import yCoupon from "./design-r-coms-e/y-coupon";
import yJoin from "./design-r-coms-e/y-join";
import yCountdown from "./design-r-coms-e/y-countdown";
import yMerchantList from "./design-r-coms-e/y-merchant-list";
import yOrderBulletChat from "./design-r-coms-e/y-order-bullet-chat";
import yTurntable from"./design-r-coms-e/y-turntable";
import yOrderList from "./design-r-coms-e/y-order-list";
import yRedPacketList from "./design-r-coms-e/y-red-packet-list";
import yBottomBtn from "./design-r-coms-e/y-bottom-btn";
import yVisitList from "./design-r-coms-e/y-visit-list";
import yShareGift from "./design-r-coms-e/y-share-gift";
import yFloatBtn from "./design-r-coms-e/y-float-btn";
import yTopTabMcr from "./design-r-coms-e/y-top-tab-mcr";
import yCarForm from "./design-r-coms-e/y-car-form";
import yGroupCode from "./design-r-coms-e/y-group-code";
import yDrawGrid from "./design-r-coms-e/y-draw-grid";
import yDrawBox from "./design-r-coms-e/y-draw-box";
import yImageTabs from "./design-r-coms-e/y-image-tabs";
import yNormalProgress from "./design-r-coms-e/y-normal-progress";

import yGroupBuyCanApplyOrder from "./design-r-coms-e/y-group-buy-can-apply-order";
import yGroupBuyBottomBtn from "./design-r-coms-e/y-group-buy-bottom-btn";

import yVoiceRedPacketList from "./design-r-coms-e/y-voice-red-packet-list";
import yVoiceRedPacketCoupons from "./design-r-coms-e/y-voice-red-packet-coupons";

import yShopGoodsList from "./design-r-coms-e/y-shop-goods-list";
export default {
  name: "design-r-coms",
  components:{
    yGap,
    yImage,ySwipeImage,yEditor,yVideo,yColumn,ySpecialBtn,yMap,

      yNormalProgress,

    yCoupon,yJoin,yCountdown,yMerchantList,yOrderBulletChat,yTurntable,
    yOrderList,yRedPacketList,yBottomBtn,yVisitList,yShareGift,yFloatBtn,
    yGroupCode,yDrawGrid,yDrawBox,yImageTabs,

    //拼团组件
    yGroupBuyCanApplyOrder,yGroupBuyBottomBtn,

    yTopTabMcr,
    yCarForm,

    //音频红包插件组件
    yVoiceRedPacketList,yVoiceRedPacketCoupons,yShopGoodsList
  },
  props:{
    coms:{
      type:Object,
      default(){
        return{}
      }
    }
  },
  methods:{
    onChange(e){
      this.$emit("change",e);
    },
    del(){
      this.$emit("del");
    },
  }
}
</script>
<style>
.el-collapse{
  border-top: unset !important;
}
.el-collapse-item__header{
  border-bottom: unset !important;
}
.el-input-group__append, .el-input-group__prepend{
  padding: 0 5px !important;
}
</style>
<style scoped>

</style>
export default {
    title:"基础组件",
    name:"basic",
    list:[
        {
            title:"",
            name:"y-placeholderview",
            data:{}
        },
        {
            title:"占位间隔",
            name:"y-gap",
            data:{
                height:100,
                bg_color:"#ededed",
            }
        },
        {
            title:"单图组",
            name:"y-image",
            data:{
                list:[
                    {
                        src:"",
                        link:"",
                    }
                ],
                margin_top:0,
                margin_bottom:0,
                margin_lr:0,
                border_r_top:0,
                border_r_bottom:0,
                shadow_color:"",
                shadow_h:0,
                shadow_v:0,
                shadow_blur:0,
                shadow_spread:0,
            }
        },
        {
            title:"选项卡图组",
            name:"y-image-tabs",
            data:{
                tabLeft: 0,
                current: 0,
                mode: "dark", //dark line border
                bg_color: "red",
                text_color: "#888888",
                text_active_color: "#ffffff",
                active_color: "#ff7700",
                active_border: 5, //0-12
                list: []
            }
        },
        {
            title:"轮播图",
            name:"y-swipe-image",
            data:{
                list:[
                    {
                        src:"",
                        link:"",
                    }
                ],
                margin_top:0,
                margin_bottom:0,
                margin_lr:0,
                border_r_top:0,
                border_r_bottom:0,
            }
        },
        {
            title:"富文本",
            name:"y-editor",
            data:{
                content:"",
                bg_color:"#ffffff",
                content_padding:0,
                margin_top:0,
                margin_bottom:0,
                margin_lr:0,
                border_r_top:0,
                border_r_bottom:0,
                shadow_color:"",
                shadow_h:0,
                shadow_v:0,
                shadow_blur:0,
                shadow_spread:0,
            }
        },
        {
            title:"视频",
            name:"y-video",
            data:{
                size:0,
                pic:"",
                src:"",
                auto_play:false,
                margin_top:0,
                margin_bottom:0,
                margin_lr:0,
                border_r_top:0,
                border_r_bottom:0,
                shadow_color:"",
                shadow_h:0,
                shadow_v:0,
                shadow_blur:0,
                shadow_spread:0,
            }
        },
        {
            title:"文章栏目",
            name:"y-column",
            data:{
                list:[
                    {
                        title: "",
                        content:""
                    }
                ],
                bg_color:"#ffffff",
                margin_top:0,
                margin_bottom:0,
                margin_lr:0,
                border_r_top:0,
                border_r_bottom:0,
                shadow_color:"",
                shadow_h:0,
                shadow_v:0,
                shadow_blur:0,
                shadow_spread:0,
            }
        },
        {
            title:"特效按钮",
            name:"y-special-btn",
            data:{
                title:"特效按钮",
                link:"",
                copy:"",
                scale:0,
                width:80,
                height:30,
                title_size:14,
                title_color:"#ffffff",
                bg_color_0:"rgba(232, 7, 22, 1)",
                bg_color_1:"rgba(13, 190, 225, 1)",
                bg_color_pos:120,
                margin_top:20,
                margin_bottom:20,
                margin_lr:0,
                border_r_top:20,
                border_r_bottom:20,
                shadow_color:"",
                shadow_h:0,
                shadow_v:0,
                shadow_blur:0,
                shadow_spread:0,
            }
        },
        {
            title:"地图",
            name:"y-map",
            data:{
                title:"",
                pic:"",
                desc:"",
                margin_top:0,
                margin_bottom:0,
                margin_lr:0,
                border_r_top:0,
                border_r_bottom:0,
                shadow_color:"",
                shadow_h:0,
                shadow_v:0,
                shadow_blur:0,
                shadow_spread:0,
            }
        },
    ]
}
<template>
  <div style="width: 100%">
    <el-collapse v-model="activeColumn" accordion>
      <template v-for="(item,key) in con_arr">
        <el-collapse-item :name="item.name" :key="key">
          <template slot="title">
            <div class="flex-def flex-cCenter" style="font-size: 9px;font-weight:600;width: 100%;color: #888888">
              {{ item.title }}
            </div>
          </template>
          <div class="flex-def flex-warp">
            <div v-for="(v,index) in item.list" :key="v.name">
              <div v-if="v.name !== 'y-placeholderview'"
                   class="left-con-box flex-def flex-zCenter flex-cCenter flex-zTopBottom" draggable="true"
                   @click="conAdd(key,index)" @dragstart="condrop($event,key,index)">
                <img :src="elIcon(v.name)" style="height: 40px;width:40px;" class="flex-def" alt="">
                <span style="user-select:none;">{{ v.title }}</span>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </template>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "design-l-con",
  props: {
    coms: {
      type: Array,
      default() {
        return [];
      }
    },
    con_arr: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data(){
    return{
      activeColumn:"basic"
    }
  },
  methods: {
    elIcon(name) {
      return `./${process.env.BASE_URL}icon/design/${name}.png`
    },
    Find(target, array) {
      try {
        array.forEach(item => {
          if (item.name == target) {
            throw "has"
          }
        });
        return false;
      } catch (e) {
        return true;
      }
    },
    canUse(index, key) {
      let item = this.con_arr[index].list[key];
      let basic = ["y-gap", "y-image", "y-swipe-image", "y-editor", "y-video","y-special-btn"];
      if (!basic.includes(item.name) && this.Find(item.name, this.coms)) {
        this.$message.error(`${item.title}仅可添加1个`);
        return false;
      }
      let justOne = ["y-merchant-list", "y-coupon", "y-red-packet-list"];
      if (justOne.includes(item.name) && this.Find("y-top-tab-mcr", this.coms)) {
        this.$message.error(`${item.title}不可与聚合选项卡同时存在,如需添加请先删除聚合选项卡`);
        return false;
      }
      let drawJustOne = ["y-turntable","y-draw-grid","y-draw-box"];
      if (drawJustOne.includes(item.name)){
        let hasTurntable =  this.coms.filter(e=>e.name === "y-turntable")
        let hasDrawGrid =  this.coms.filter(e=>e.name === "y-draw-grid")
        let hasDrawBox =  this.coms.filter(e=>e.name === "y-draw-box")
        if (hasDrawGrid.length || hasTurntable.length || hasDrawBox.length){
          this.$message.error(`大转盘、九宫格、盲盒不可同时添加`);
          return false
        }
      }
      if (item.name == "y-top-tab-mcr") {
        if (this.Find("y-merchant-list", this.coms)) {
          this.$message.error(`聚合选项卡不可与商家列表同时存在,如需添加请先删除商家列表`);
          return false;
        }
        if (this.Find("y-coupon", this.coms)) {
          this.$message.error(`聚合选项卡不可与活动卡券同时存在,如需添加请先删除活动卡券`);
          return false;
        }
        if (this.Find("y-red-packet-list", this.coms)) {
          this.$message.error(`聚合选项卡不可与红包排行同时存在,如需添加请先删除红包排行`);
          return false;
        }
      }
      return true;
    },
    conAdd(index, key) {
      if (!this.canUse(index, key)) {
        return;
      }
      this.$emit("con_add", index, key);
    },
    condrop(e, index, key) {
      if (!this.canUse(index, key)) {
        e.preventDefault();
        return false;
      }
      e.dataTransfer.setData("con_index", index)
      e.dataTransfer.setData("con_key", key)
    },
  }
}
</script>

<style scoped>
.left-con-box {
  margin-bottom: .5rem;
  width: 90px;
  height: 90px;
  cursor: pointer;
  background-color: #ededed;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  border-radius: 5px;
}
</style>

import basic from "@/view/active/edit/design/coms/basic/default";
import business from "@/view/active/edit/design/coms/business/mode1";
import variant from "@/view/active/edit/design/coms/variant/default";
import industry from "@/view/active/edit/design/coms/industry/default";

export default [
    basic,
    business,
    variant,
    industry
]

export default [
    {
        title: "活动卡券",
        name: "y-coupon",
        data: {
            bg_color: "",
            style: 0,
            margin_top: 25,
            margin_bottom: 25,
            margin_lr: 25,
            border_r_top: 25,
            border_r_bottom: 25,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "参与卡片",
        name: "y-join",
        data: {
            bg_color: "#ffffff",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "倒计时",
        name: "y-countdown",
        data: {
            bg_img: "",
            num_m_t: 0,
            num_a: "center",
            num_size: 21,
            num_color: "#ffffff",
            txt_size: 21,
            bg_color: "",
            time_bg_color: "#ff7700",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "商家列表",
        name: "y-merchant-list",
        data: {
            col: 2,
            col_show: 1,
            logo_border_radius: 0,
            bg_color: "#ffffff",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "订单弹幕",
        name: "y-order-bullet-chat",
        data: {
            pos_type: "rightToLeft",
        }
    },
    {
        title: "大转盘",
        name: "y-turntable",
        data: {
            str_font_size: 12,
            bg_color: "#ffffff",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "九宫格",
        name: "y-draw-grid",
        data: {
            bg_color: "rgba(255, 255, 255, 0)",
            bg_content_color: "#fde69a",
            bg_image:"",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "盲盒",
        name: "y-draw-box",
        data: {
            bg_color: "rgba(255, 255, 255, 0)",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "订单列表",
        name: "y-order-list",
        data: {
            style: 0,
            title_bg_color: "",
            bg_color: "#ffffff",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "红包排行",
        name: "y-red-packet-list",
        data: {
            bg_color: "#ffffff",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "访客列表",
        name: "y-visit-list",
        data: {
            col_show: 4,
            bg_color: "#ffffff",
            content_padding: 0,
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "推荐礼品",
        name: "y-share-gift",
        data: {
            bg_color: "#ffffff",
            content_padding: 0,
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "浮动按钮",
        name: "y-float-btn",
        data: {
            src: "",
            link: "",
            img_width: 80,
            pos_tb: "bottom",
            pos_lr: "right",
            margin_top: 0,
            margin_bottom: 0,
            margin_left: 0,
            margin_right: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
    {
        title: "入群口令",
        name: "y-group-code",
        data: {
            pic: "",
            title: "",
            desc: "",
            margin_top: 0,
            margin_bottom: 0,
            margin_lr: 0,
            border_r_top: 0,
            border_r_bottom: 0,
            shadow_color: "",
            shadow_h: 0,
            shadow_v: 0,
            shadow_blur: 0,
            shadow_spread: 0,
        }
    },
]
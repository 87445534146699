<template>
  <div style="padding: 2rem">
    <div class="header-title">组件管理</div>
    <div style="text-align: right">
      <el-link @click="clear" style="font-size: 12px" type="primary" :underline="false">清除组件</el-link>
    </div>
    <div style="margin-top: 1rem;">
      <draggable v-model="coms_con"  chosenClass="chosen" animation="1000" @end="onEnd">
        <transition-group>
          <div class="item panel-shadow flex-def flex-zBetween flex-cCenter" v-for="(item,key) in coms" :key="'key_' + key">
            <div style="user-select:none;">
              <i class="el-icon-rank"></i>
              {{item.title}}
            </div>
            <i @click="del(key)" style="cursor: pointer" class="el-icon-delete"></i>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "con_com",
  components:{draggable},
  props:{
    coms:{
      type:Array,
      default(){
        return [];
      }
    }
  },
  data(){
    return{
      coms_con:this.coms
    }
  },
  watch:{
    coms(){
      this.coms_con=this.coms;
    }
  },
  methods:{
    onEnd(){
      this.$emit("change",this.coms_con);
    },
    del(index){
      this.coms_con.splice(index,1);
      this.$emit("change",this.coms_con);
    },
    clear(){
      this.coms_con = [];
      this.$emit("change",this.coms_con);
    }
  }
}
</script>

<style scoped>
  .item{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ededed;
    margin: auto auto 5px;
    padding: .5rem;
    cursor: move;
    box-shadow: -2px 5px 5px #ededed;
    font-size: 12px;
  }
</style>

import basic from "@/view/active/edit/design/coms/basic/default";
import business from "@/view/active/edit/design/coms/business/mode1";
import variant from "@/view/active/edit/design/coms/variant/default";
import industry from "@/view/active/edit/design/coms/industry/default";
const useB = {
    ...business,
    list:[...business.list,{
        title:"活动进度",
        name:"y-normal-progress",
        data:{
            active_color:"#f82e52",
            margin_top:0,
            margin_bottom:0,
            margin_lr:0,
            border_r_top:0,
            border_r_bottom:0,
            shadow_color:"",
            shadow_h:0,
            shadow_v:0,
            shadow_blur:0,
            shadow_spread:0,
        }
    }]
}
export default [
    basic,
    useB,
    variant,
    industry
]

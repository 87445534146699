<template>
  <el-collapse v-model="activeName" accordion>
    <el-collapse-item title="选项卡" name="1">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">模式</span></el-col>
        <el-col :span="19">
          <el-radio-group @change="onChange" size="small" v-model="coms_con.data.mode">
            <el-radio-button label="dark">背景</el-radio-button>
            <el-radio-button label="line">下划线</el-radio-button>
            <el-radio-button label="border">边框</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="6"><span class="y-desc">背景色</span></el-col>
        <el-col :span="18">
          <y_color_choose @change="onChange" :alpha="true" v-model="coms_con.data.bg_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="6"><span class="y-desc">默认文本色</span></el-col>
        <el-col :span="18">
          <y_color_choose @change="onChange" v-model="coms_con.data.text_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="6"><span class="y-desc">激活文本色</span></el-col>
        <el-col :span="18">
          <y_color_choose @change="onChange" v-model="coms_con.data.text_active_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="6"><span class="y-desc">激活边框色</span></el-col>
        <el-col :span="18">
          <y_color_choose @change="onChange" v-model="coms_con.data.active_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">边框圆角</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :min="0" :max="12" input-size="mini" v-model="coms_con.data.active_border"
                     show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item title="图组管理" name="2">
      <draggable v-model="coms_con.data.list"   chosenClass="chosen" animation="1000" @end="onSortEnd">
        <transition-group>
          <div class="item flex-def flex-zBetween flex-cCenter" style="margin-bottom: .5rem" v-for="(item,key) in coms_con.data.list" :key="'key_' + key">
            <div class="flex-def flex-cCenter" style="user-select:none;">
              <i class="el-icon-rank" style="font-size: 1rem;margin-right: .5rem"></i>
              <div class="flex-def">
                <el-input @change="onChange" v-model="item.name" size="mini"></el-input>
                <el-button @click="editImage(item)" size="mini" style="margin-left: .5rem">编辑图组</el-button>
              </div>
            </div>
            <i v-if="key !== 0" @click="delItem(key)" style="cursor: pointer" class="el-icon-delete"></i>
          </div>
        </transition-group>
      </draggable>
      <el-button @click="addImgItem" size="mini" style="width: 100%;margin-top: .5rem">+添加图组</el-button>
    </el-collapse-item>
    <el-dialog :visible.sync="imgListShow" title="图组编辑" @close="close">
      <yImage @change="yImageChange" :coms="{data:editForm}"></yImage>
    </el-dialog>
  </el-collapse>
</template>

<script>
import draggable from "vuedraggable";
import yImage from "./y-image";
import y_color_choose from "@/components/y_color_choose";
// import y_choose_link from "@/components/y_choose_link";

export default {
  name: "y-image-tabs",
  components: {
    draggable,
    y_color_choose,
    yImage,
    // y_choose_link,
  },
  data() {
    return {
      coms_con: this.coms,
      activeName: "1",
      imgListShow:false,
      editForm:{
        list:[],
        margin_top:0,
        margin_bottom:0,
        margin_lr:0,
        border_r_top:0,
        border_r_bottom:0,
        shadow_color:"",
        shadow_h:0,
        shadow_v:0,
        shadow_blur:0,
        shadow_spread:0,
      },
    }
  },
  props: {
    coms: {
      type: Object,
      default() {
        return {
          title: "选项卡图组",
          name: "y-image-tabs",
          data: {
            current: 0,
            mode: "dark", //dark line border
            bg_color: "red",
            text_color: "#888888",
            text_active_color: "#ffffff",
            active_color: "#ff7700",
            active_border: 5, //0-12
            list: []
          }
        }
      }
    }
  },
  watch: {
    coms() {
      this.coms_con = this.coms;
    }
  },
  methods: {
    addImgItem(){
      this.coms_con.data.list.push({
        name: "选项",
        image: {
          list:[],
          margin_top:0,
          margin_bottom:0,
          margin_lr:0,
          border_r_top:0,
          border_r_bottom:0,
          shadow_color:"",
          shadow_h:0,
          shadow_v:0,
          shadow_blur:0,
          shadow_spread:0,
        },
      })
      this.onChange();
    },
    delItem(key){
      this.coms_con.data.list.splice(key,1);
      this.onChange();
    },
    editImage(item){
      this.editForm = item.image;
      this.imgListShow = true;
    },
    close(){
      this.editForm = {
        list:[],
        margin_top:0,
        margin_bottom:0,
        margin_lr:0,
        border_r_top:0,
        border_r_bottom:0,
        shadow_color:"",
        shadow_h:0,
        shadow_v:0,
        shadow_blur:0,
        shadow_spread:0,
      }
    },
    onSortEnd(){
      console.log("sort end");
      this.onChange();
    },
    yImageChange(e){
      this.editForm = e.data;
      this.onChange();
    },
    onChange() {
      this.$emit("change", this.coms_con);
    }
  }
}
</script>

<style scoped>
.y-desc {
  font-weight: 600;
}

.el-row {
  margin-bottom: 1rem;
}
</style>
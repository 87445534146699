
export default {
    title: "业务组件",
    name: "business",
    list: [
        {
            title: "商家列表",
            name: "y-merchant-list",
            data: {
                col: 2,
                col_show: 1,
                logo_border_radius: 0,
                bg_color: "#ffffff",
                margin_top: 0,
                margin_bottom: 0,
                margin_lr: 0,
                border_r_top: 0,
                border_r_bottom: 0,
                shadow_color: "",
                shadow_h: 0,
                shadow_v: 0,
                shadow_blur: 0,
                shadow_spread: 0,
            }
        },
        {
            title: "订单弹幕",
            name: "y-order-bullet-chat",
            data: {
                pos_type: "rightToLeft",
            }
        },
        {
            title: "订单列表",
            name: "y-order-list",
            data: {
                style: 0,
                title_bg_color: "",
                bg_color: "#ffffff",
                margin_top: 0,
                margin_bottom: 0,
                margin_lr: 0,
                border_r_top: 0,
                border_r_bottom: 0,
                shadow_color: "",
                shadow_h: 0,
                shadow_v: 0,
                shadow_blur: 0,
                shadow_spread: 0,
            }
        },
        {
            title: "红包排行",
            name: "y-red-packet-list",
            data: {
                bg_color: "#ffffff",
                margin_top: 0,
                margin_bottom: 0,
                margin_lr: 0,
                border_r_top: 0,
                border_r_bottom: 0,
                shadow_color: "",
                shadow_h: 0,
                shadow_v: 0,
                shadow_blur: 0,
                shadow_spread: 0,
            }
        },
        {
            title: "访客列表",
            name: "y-visit-list",
            data: {
                col_show: 4,
                bg_color: "#ffffff",
                content_padding: 0,
                margin_top: 0,
                margin_bottom: 0,
                margin_lr: 0,
                border_r_top: 0,
                border_r_bottom: 0,
                shadow_color: "",
                shadow_h: 0,
                shadow_v: 0,
                shadow_blur: 0,
                shadow_spread: 0,
            }
        },
        {
            title: "浮动按钮",
            name: "y-float-btn",
            data: {
                src: "",
                link: "",
                img_width: 80,
                pos_tb: "bottom",
                pos_lr: "right",
                margin_top: 0,
                margin_bottom: 0,
                margin_left: 0,
                margin_right: 0,
                border_r_top: 0,
                border_r_bottom: 0,
                shadow_color: "",
                shadow_h: 0,
                shadow_v: 0,
                shadow_blur: 0,
                shadow_spread: 0,
            }
        },
        {
            title: "入群口令",
            name: "y-group-code",
            data: {
                pic: "",
                title: "",
                desc: "",
                margin_top: 0,
                margin_bottom: 0,
                margin_lr: 0,
                border_r_top: 0,
                border_r_bottom: 0,
                shadow_color: "",
                shadow_h: 0,
                shadow_v: 0,
                shadow_blur: 0,
                shadow_spread: 0,
            }
        },
        {
            title: "商品列表",
            name: "y-shop-goods-list",
            data: {
                bg_color: "#EBEFBF",
                margin_top: 10,
                margin_bottom: 10,
                shadow_color: "",
                shadow_h: 0,
                shadow_v: 0,
                shadow_blur: 0,
                shadow_spread: 0,
            }
        },
    ]
}
export default {
    title: "行业组件",
    name: "industry",
    list: [
        {
            title: "汽车表单",
            name: "y-car-form",
            data: {
                btn_text: "立即抢购",
                btn_text_color: "#ffffff",
                btn_bg_color: "#ff7700",
                bg_color: "#ffffff",
                margin_top: 0,
                margin_bottom: 0,
                margin_lr: 0,
                border_r_top: 0,
                border_r_bottom: 0,
                shadow_color: "",
                shadow_h: 0,
                shadow_v: 0,
                shadow_blur: 0,
                shadow_spread: 0,
            }
        },
    ],
}
<template>
  <el-collapse v-model="activeName" accordion>
    <el-collapse-item title="内容" name="1">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">封面</span></el-col>
        <el-col :span="19">
          <y_upload_img_box @change="onChange" v-model="coms_con.data.pic"></y_upload_img_box>
        </el-col>
      </el-row>
      <el-row style="margin-top: 1rem" :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">视频素材</span></el-col>
        <el-col :span="19">
          <el-input placeholder="可输入视频外链" @input="onChange" style="width: 100%" v-model="coms_con.data.src" size="mini"></el-input>
          <y_upload_video_box @change="onChange" v-model="coms_con.data.src"></y_upload_video_box>
        </el-col>
      </el-row>
      <el-row style="margin-top: 1rem" :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">自动播放</span></el-col>
        <el-col :span="19">
          <el-switch @change="onChange" v-model="coms_con.data.auto_play"></el-switch>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item title="样式" name="2">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">视频尺寸</span></el-col>
        <el-col :span="19">
          <el-radio-group @change="onChange" size="small" v-model="coms_con.data.size">
            <el-radio-button :label="0">16:9</el-radio-button>
            <el-radio-button :label="1">1:1</el-radio-button>
            <el-radio-button :label="2">4:3</el-radio-button>
            <el-radio-button :label="3">9:16</el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">上间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_top" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">下间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_bottom" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">左右间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_lr" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">上边角</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.border_r_top" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">下边角</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.border_r_bottom" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item title="阴影效果" name="3">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">阴影色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" :alpha="true" v-model="coms_con.data.shadow_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">水平</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="25" :min="-25" input-size="mini" v-model="coms_con.data.shadow_h" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">垂直</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="25" :min="-25" input-size="mini" v-model="coms_con.data.shadow_v" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">模糊</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.shadow_blur" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">尺寸</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.shadow_spread" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import y_upload_img_box from "@/components/y_upload_img_box";
import y_upload_video_box from "@/components/y_upload_video_box";
import y_color_choose from "@/components/y_color_choose";
export default {
  name: "y-video",
  components:{
    y_upload_img_box,
    y_upload_video_box,
    y_color_choose,
  },
  data(){
    return{
      coms_con:this.coms,
      activeName:"1",
    }
  },
  props:{
    coms:{
      type:Object,
      default(){
        return{
          title:"视频",
          name:"y-video",
          data:{
            size:0,
            pic:"",
            src:"",
            auto_play:false,
            margin_top:0,
            margin_bottom:0,
            margin_lr:0,
            border_r_top:0,
            border_r_bottom:0,
            shadow_color:"",
            shadow_h:0,
            shadow_v:0,
            shadow_blur:0,
            shadow_spread:0,
          }
        }
      }
    }
  },
  watch:{
    coms(){
      this.coms_con = this.coms;
    }
  },
  methods:{
    onChange(){
      this.$emit("change",this.coms_con);
    }
  }
}
</script>

<style scoped>
.y-desc{
  font-weight: 600;
}
.el-row{
  margin-bottom: 1rem;
}
</style>
<template>
  <el-collapse v-model="activeName" accordion>
    <el-collapse-item title="内容" name="1">
      <div class="item flex-def flex-zBetween flex-cCenter" v-for="(item,key) in coms_con.data.list" :key="'key_' + key">
        <div style="user-select:none;width: 90%">
          <div class="flex-def flex-cCenter" >
            <div class="y-desc" style="width: 2rem">标题</div>
            <el-input @input="onChange" style="width: 100%" v-model="item.title" size="mini"></el-input>
          </div>
          <div style="width: 100%;">
            <y_editor :eid="'content'+key" @change="onChange" v-model="item.content"></y_editor>
          </div>
        </div>
        <i v-if="key !== 0" @click="delItem(key)" style="cursor: pointer" class="el-icon-delete"></i>
      </div>
      <el-button @click="addImgItem" size="mini" style="width: 100%;margin-top: .5rem">+添加栏目</el-button>
    </el-collapse-item>
    <el-collapse-item title="样式" name="2">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">背景色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" :alpha="true" v-model="coms_con.data.bg_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">上间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_top" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">下间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_bottom" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">左右间距</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.margin_lr" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">上边角</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.border_r_top" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">下边角</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.border_r_bottom" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
    <el-collapse-item title="阴影效果" name="3">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">阴影色</span></el-col>
        <el-col :span="19">
          <y_color_choose @change="onChange" :alpha="true" v-model="coms_con.data.shadow_color"></y_color_choose>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">水平</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="25" :min="-25" input-size="mini" v-model="coms_con.data.shadow_h" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">垂直</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="25" :min="-25" input-size="mini" v-model="coms_con.data.shadow_v" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">模糊</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.shadow_blur" show-input></el-slider>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5"><span class="y-desc">尺寸</span></el-col>
        <el-col :span="19">
          <el-slider @input="onChange" :max="50" input-size="mini" v-model="coms_con.data.shadow_spread" show-input></el-slider>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import y_color_choose from "@/components/y_color_choose";
import y_editor from "@/components/editor/y_editor";
export default {
  name: "y-column",
  components:{
    y_color_choose,
    y_editor,
  },
  data(){
    return{
      coms_con:this.coms,
      activeName:"1",
    }
  },
  props:{
    coms:{
      type:Object,
      default(){
        return{
          title:"文章栏目",
          name:"y-column",
          data:{
            list:[],
            bg_color:"#ffffff",
            margin_top:0,
            margin_bottom:0,
            margin_lr:0,
            border_r_top:0,
            border_r_bottom:0,
            shadow_color:"",
            shadow_h:0,
            shadow_v:0,
            shadow_blur:0,
            shadow_spread:0,
          }
        }
      }
    }
  },
  watch:{
    coms(){
      this.coms_con = this.coms;
    }
  },
  methods:{
    addImgItem(){
      this.coms_con.data.list.push({
        src:"",
        link:""
      })
    },
    delItem(key){
      this.coms_con.data.list.splice(key,1);
      this.onChange();
    },
    onSortEnd(){
      console.log("sort end")
    },
    onChange(){
      console.log("changeing ")
      this.$emit("change",this.coms_con);
    }
  }
}
</script>

<style scoped>
.item{
  padding: .5rem;
  background-color: #f8f8f8;
  margin-bottom: .5rem;
}
.y-desc{
  font-weight: 600;
}
.el-row{
  margin-bottom: 1rem;
}
</style>